import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  return (
    <Layout path={location.pathname} title="קטע מספר 3 גבולות – צאלים">
      <SectionContent title="קטע מספר 3 גבולות – צאלים">
        <div>
          <p>
            <strong>אורך הקטע: 9.66 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            חוזרים ממצפה גבולות לכיוון דרום ל'דרך השדות' ופונים שמאלה לכיוון
            כללי דרום-מזרח, מזרח לאורך 'שביל השדות'.  לאחר כ- 3 ק&quot;מ הדרך
            מתעקלת שמאלה לכיוון כללי צפון, ובהמשך (ק&quot;מ 4.2) מתעקלת בחדות
            לכיוון דרום מזרח. ממשיכים לרוץ לאורך הדרך שמתעקלת בחדות ימינה
            (ק&quot;מ 8.4) כשהפרדסים מצד שמאל עוד כ-800 מטר עד לחורשת הזיתים
            בכניסה לקיבוץ צאלים.
          </p>
          <p>
            <strong>טופוגרפיה</strong>: בחלק הראשון מגמת עליה מתונה. בחלק השני
            מגמת ירידה מתונה.
          </p>
          <p>
            <strong>מסלול</strong>: כביש שדות.
          </p>
          <p>
            <strong>הערות</strong> :  נשארים צמודים לאור 'דרך השדות' שהיא הדרך
            הדומיננטית במסלול. שימו לב! ב1.2 ק&quot;מ האחרונים זו דרך משותפת עם
            תחילת מקטע 4 הרצים בכיוון הנגדי – לא להתבלבל.
          </p>
          <p>
            <strong>בטיחות</strong>: ריצה בשולי הכביש. זה כביש פנימי המשמש את
            חקלאי האזור.
          </p>
          <p>
            <strong>תחנת החלפה</strong> : ברחבת החניה הצמודה לחורשת הזיתים
            בכניסה לקיבוץ צאלים.
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="//www.gpsies.com/mapOnly.do?fileId=urqbkttwfwrtppkj"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
